import countriesData from "@components/intl-phone-input/countries.json";
import { Country } from "@components/intl-phone-input/countries";

export const isJSXElement = (elm: string | JSX.Element | undefined): elm is JSX.Element => {
    return typeof elm !== "string";
};

export const verifyPhoneNumber = (text: string): boolean => {
    let isPhnNo = false;
    if (text.length > 15) return false;
    if (text.length === 0) return true;
    for (let i = 0; i < text.length; i++) {
        const ascii = text.charCodeAt(i);
        if ((ascii >= 48 && ascii <= 57) || (ascii === 43 && i === 0)) {
            isPhnNo = true;
        } else {
            return false;
        }
    }
    return isPhnNo;
};
export const WORK_EMAIL_REGEX = /^(?!.+@(gmail|yahoo|hotmail|yahoo|aol|abc|xyz|pqr|rediffmail|live|outlook|me|msn|ymail)\..+)(.+@.+\..+)$/i;
export const LINKED_IN_URL = /\b((http|https):\/\/)?(www\.)?linkedin\.com\/(pub|in|profile)\/[a-zA-Z0-9_-]+\/?\b/;
export const WEBSITE_REGEX = /^(https?:\/\/)?(www\.)?[a-zA-Z0-9-]+(\.[a-zA-Z]{2,})+(\/[^\s]*)?$/;

export const windowOpenNewTab = (link: string): any => {
    const a = document.createElement("a");
    a.setAttribute("href", link);
    a.setAttribute("target", "_blank");
    a.click();
    a.remove();
};

export const sentenceCase = (item: string | undefined): string | undefined => {
    if (!item) return item;
    return item.trim().charAt(0).toUpperCase() + item.trim().slice(1).toLowerCase();
};

export const isPhnNoWithCountryCode = (phn_no: string): boolean => {
    return phn_no[0] === "+";
};

export const getCountryIsoFromDialCode = (dial_code: string): string | undefined => {
    const country = countriesData.find((country: Country) => country.prefix === dial_code);
    return country?.name;
};
