import React, { useMemo, useState } from "react";
import { WebinarListFEType } from "./Webinar";
import { zodResolver } from "@hookform/resolvers/zod";
import { Controller, SubmitHandler, useForm } from "react-hook-form";
import IntlTelInput, { CountryData } from "react-intl-tel-input";
import "react-intl-tel-input/dist/main.css";
import ClipLoader from "react-spinners/ClipLoader";
import { verifyPhoneNumber, WEBSITE_REGEX } from "utils/common";
import { z } from "zod";
import { IoCloseOutline } from "react-icons/io5";
import { SlCalender } from "react-icons/sl";
import { MdOutlineAccessTime } from "react-icons/md";
import { GoChevronLeft } from "react-icons/go";
import useRecaptcha from "@hooks/useRecaptcha";
import ReCAPTCHA from "react-google-recaptcha";
import axios from "axios";
import { SourceInformation } from "@utils/campaign-tracker";
import { getCountryIsoFromDialCode } from "@utils/common";

interface BookDemoFormProps {
    selectedWebinar: WebinarListFEType | null | undefined;
    handleTimeSlotPick: () => void;
    closeModal: () => void;
    handleRegister: () => void;
    setError: React.Dispatch<React.SetStateAction<string | null>>;
    campaignDetails: SourceInformation | undefined;
    isStandalonePage: boolean;
}
interface zoomApiResponseProps {
    id: number;
    join_url: string;
    registrant_id: string;
    start_time: string;
    topic: string;
}

const InputCustomStyle = {
    backgroundColor: "#fff",
    fontSize: "14px",
    paddingTop: "8px",
    paddingBottom: "8px",
    paddingLeft: "8px",
    paddingRight: "8px",
    borderRadius: "5px",
};

interface FormData {
    first_name?: string;
    email?: string;
    phone?: string;
    website?: string;
    country?: string | undefined;
    campaignDetails: SourceInformation | undefined;
}

const FormInputSchema = z.object({
    first_name: z.string().min(1, "First Name is required").max(64, "Maximum limit exceeded"),
    email: z.string().min(1, "Email is required"),
    phone: z.string().refine((value) => verifyPhoneNumber(value), "Invalid phone number"),
    website: z.string().regex(WEBSITE_REGEX, "Invalid website URL"),
});

type FormInputProps = z.infer<typeof FormInputSchema>;
const siteKey = process.env.NEXT_PUBLIC_API_RECAPTCHA_SITE_KEY || "";

const registerForWebinar = async (webinarId: number, formdata: FormData): Promise<zoomApiResponseProps | void> => {
    const payload = {
        webinarId,
        ...formdata,
    };
    try {
        const response = await axios.post("/api/v1/zoom-webinar/register", payload);
        return response.data;
    } catch (error) {
        throw new Error("Something went wrong. Please try after sometime");
    }
};

const BookDemoForm: React.FC<BookDemoFormProps> = ({ handleTimeSlotPick, closeModal, selectedWebinar, handleRegister, setError, campaignDetails, isStandalonePage }) => {
    const {
        register,
        handleSubmit,
        control,
        reset,
        formState: { errors },
    } = useForm<FormInputProps>({ resolver: zodResolver(FormInputSchema) });

    const [countryCode, setCountryCode] = useState<string | undefined>("91");
    const [isLoading, setIsLoading] = useState(false);
    const [isSubmitted, setIsSubmitted] = useState(false);
    const [formData, setFormData] = useState<FormInputProps | null>(null);

    const { generateToken, reCaptchaRef, triggerReCaptcha } = useRecaptcha(async (token: string | null) => {
        if (token) {
            try {
                await zoomRegister();
                setIsLoading(false);
                setIsSubmitted(false);
                reset();
                handleRegister();
            } catch (error) {
                setIsLoading(false);
                setIsSubmitted(false);
                handleRegister();
                if (error instanceof Error) {
                    setError(error.message);
                } else {
                    setError("An unexpected error occurred.");
                }
            }
        }
    });

    const { webinar_start_time, webinar_start_date } = useMemo(() => {
        const webinar_start_time = selectedWebinar?.start_time.format("hh:mm A");
        const webinar_start_date = selectedWebinar?.start_time.format("dddd, MMMM DD, YYYY");
        return { webinar_start_time, webinar_start_date };
    }, [selectedWebinar]);

    const country = useMemo(() => getCountryIsoFromDialCode(`+${countryCode}`), [countryCode]);

    const zoomRegister = async (): Promise<zoomApiResponseProps | void> => {
        try {
            if (formData && selectedWebinar?.id) {
                await registerForWebinar(selectedWebinar.id, {
                    first_name: formData.first_name,
                    email: formData.email,
                    phone: formData.phone,
                    website: formData.website,
                    country: country,
                    campaignDetails: campaignDetails,
                });
            }
        } catch (error) {
            throw new Error("Something went wrong. Please try again later");
        }
    };

    const onSubmit: SubmitHandler<FormInputProps> = async (data) => {
        setFormData(data);
        setIsLoading(true);
        setIsSubmitted(true);
        triggerReCaptcha();
    };

    return (
        <div className="w-full">
            <div className="flex justify-between items-center lg:gap-0 gap-8 md:pr-0">
                <h2 className="lg:text-xl text-lg text-gray-700 font-semibold lg:pl-0  w-full gap-x-4 flex items-center">
                    <GoChevronLeft
                        size="23px"
                        width={20}
                        height={20}
                        className="text-gray-500 rounded-full hover:shadow-lg hover:text-white hover:bg-gray-400 self-center"
                        onClick={handleTimeSlotPick}
                    />
                    Book a demo with our experts
                </h2>
                {!isStandalonePage ? (
                    <IoCloseOutline
                        size="24px"
                        width={20}
                        height={20}
                        className="text-gray-500 rounded-full hover:shadow-lg hover:text-white hover:bg-gray-400 self-center"
                        onClick={closeModal}
                    />
                ) : null}
            </div>

            <div className="w-full md:flex md:flex-row flex-col mt-4 md:mt-8">
                <div className="md:w-1/2  w-full flex flex-col items-center md:items-start md:flex-none mt-10">
                    <img src="/gallabox-icon.png" width={90} height={90} alt="icon" />
                    <h3 className="text-gray-700 text-4xl font-semibold md:mt-20 mt-10">Gallabox Demo</h3>
                    <div className="mt-5 flex flex-col gap-y-5 items-center md:items-start">
                        <span className="flex items-center gap-x-4 text-gray-600 font-semibold">
                            <SlCalender size={20} />
                            {webinar_start_time}
                        </span>
                        <span className="flex items-center gap-x-4 text-gray-600 font-semibold">
                            <MdOutlineAccessTime size={20} />
                            {webinar_start_date}
                        </span>
                    </div>
                </div>
                <div className="md:w-1/2 w-full">
                    <form
                        onSubmit={handleSubmit(onSubmit)}
                        className="flex flex-col gap-2 w-full max-w-sm lg:-ml-8  border border-gray-300 rounded bg-white mt-10 px-2 lg:mt-0 lg:px-0"
                    >
                        <div>
                            <label htmlFor="name" className="text-gray-600 text-sm mb-1">
                                Name <span className="text-red-500">*</span>
                            </label>
                            <input
                                id="name"
                                type="text"
                                placeholder="Enter name"
                                {...register("first_name")}
                                className="h-10"
                                style={InputCustomStyle}
                                aria-invalid={errors.first_name ? "true" : "false"}
                            />

                            {errors.first_name && <p className="text-red-500 text-xs mt-1">{errors.first_name.message}</p>}
                        </div>

                        <div>
                            <label htmlFor="email" className="text-gray-600 text-sm mb-1">
                                Email <span className="text-red-500">*</span>
                            </label>
                            <input
                                id="email"
                                type="email"
                                placeholder="Enter email address"
                                {...register("email")}
                                className="h-10"
                                style={InputCustomStyle}
                                aria-invalid={errors.email ? "true" : "false"}
                            />
                            {errors.email && <p className="text-red-500 text-xs mt-2">{errors.email.message}</p>}
                        </div>

                        <div className="flex flex-col mb-2">
                            <label htmlFor="phoneNumber" className="text-gray-600 text-sm mb-1">
                                Mobile number <span className="text-red-500">*</span>
                            </label>
                            <Controller
                                name="phone"
                                control={control}
                                render={({ field: { onChange, value } }) => (
                                    <IntlTelInput
                                        containerClassName="intl-tel-input w-full h-10 no-bg"
                                        style={{ backgroundColor: "#fff" }}
                                        inputClassName="p-1 h-9 border border-gray-300 rounded text-gray-700 controller bg-white"
                                        placeholder="Enter mobile number"
                                        defaultCountry="in"
                                        value={value || ""}
                                        onPhoneNumberChange={(_isValid: boolean, value: string) => {
                                            onChange(value);
                                        }}
                                        onSelectFlag={(_e: string, countryData: CountryData) => {
                                            setCountryCode(countryData.dialCode);
                                        }}
                                    />
                                )}
                                rules={{ required: "Phone number is required" }}
                            />
                            {errors.phone && <p className="text-red-500 text-xs mt-2">{errors.phone.message}</p>}
                        </div>

                        <div>
                            <label htmlFor="workEmail" className="text-gray-600 text-sm mb-1">
                                Website <span className="text-red-500">*</span>
                            </label>
                            <input
                                id="website"
                                type="text"
                                placeholder="Enter website"
                                {...register("website")}
                                className="h-10"
                                style={InputCustomStyle}
                                aria-invalid={errors.website ? "true" : "false"}
                            />
                            {errors.website && <p className="text-red-500 text-xs mt-1">{errors.website.message}</p>}
                        </div>

                        <div className="flex justify-center md:self-end lg:justify-start pt-2">
                            <button
                                type="submit"
                                className={`w-fit p-3 rounded ${isSubmitted ? "bg-green-500 text-white" : "bg-blue-600 text-white"} hover:${
                                    isSubmitted ? "bg-green-600" : "bg-blue-800"
                                }`}
                            >
                                {isLoading ? (
                                    <span className="flex items-center gap-1">
                                        <ClipLoader color={"#ffffff"} loading={true} size={20} />
                                        <p>Submitting</p>
                                    </span>
                                ) : isSubmitted ? (
                                    <p>✔{"  "}Submitted</p>
                                ) : (
                                    <p>Book a demo</p>
                                )}
                            </button>
                        </div>
                        <ReCAPTCHA ref={reCaptchaRef} sitekey={siteKey} size="invisible" onChange={generateToken} />
                    </form>
                </div>
            </div>
        </div>
    );
};

export default BookDemoForm;

interface ThankyouModalProps {
    selectedWebinar: WebinarListFEType | null | undefined;
    closeModal: () => void;
    isStandalonePage: boolean;
}

export const ThankyouModal: React.FC<ThankyouModalProps> = ({ closeModal, selectedWebinar, isStandalonePage }) => {
    const { webinar_start_time, webinar_start_date } = useMemo(() => {
        const webinar_start_time = selectedWebinar?.start_time.format("hh:mm A");
        const webinar_start_date = selectedWebinar?.start_time.format("dddd, MMMM DD, YYYY");
        return { webinar_start_time, webinar_start_date };
    }, [selectedWebinar]);

    return (
        <div>
            <div className="flex justify-between">
                {isStandalonePage ? null : <img src="/gallabox-logo.png" alt="" width={150} height={30} />}
                {!isStandalonePage ? (
                    <IoCloseOutline
                        size="24px"
                        width={20}
                        height={20}
                        className="text-gray-500 rounded-full hover:shadow-lg hover:text-white hover:bg-gray-400 self-center"
                        onClick={closeModal}
                    />
                ) : null}
            </div>
            <h3 className="text-gray-700 text-2xl font-semibold mt-20 text-center">Demo has been scheduled</h3>
            <p className="text-gray-700 text-base mt-10 text-center">A zoom meeting link has been sent to your email ID. We look forward to speak over Zoom.</p>
            <div className="flex justify-center md:gap-10 gap-7 md:mt-20 mt-10 flex-col md:flex-row">
                <img src="/meeting.png" alt="meeting" className="mx-auto md:mx-0" />
                <div className="mt-5 flex flex-col gap-y-5 items-center">
                    <span className="flex gap-x-4 text-gray-600 font-semibold md:self-start items-center">
                        <SlCalender size={20} />
                        {webinar_start_time}
                    </span>
                    <div className="flex justify-between">
                        <span className="flex gap-x-4 text-gray-600 font-semibold">
                            <MdOutlineAccessTime size={24} />
                            {webinar_start_date}
                        </span>
                    </div>
                </div>
            </div>
        </div>
    );
};
